import { createContext, useContext, useState, ReactNode, useCallback } from 'react';

export interface UIContextInterface {
  isMuted: boolean;
  isOnlyAvailableInAppPromtOpen: boolean;
  showOnlyAvailableInAppPrompt: () => void;
  closeOnlyAvailableInAppPrompt: () => void;
  toggleMute: () => void;
}

const AuthContext = createContext<UIContextInterface>({} as UIContextInterface);

export function useUIContext() {
  return useContext(AuthContext);
}

export function UIContextProvider({ children }: { children: ReactNode | ReactNode[] }) {
  const [isMuted, setIsMuted] = useState(false);
  const [isOnlyAvailableInAppPromtOpen, setIsOnlyAvailableInAppPromptOpen] = useState(false);

  const toggleMute = () => {
    setIsMuted((prevValue) => !prevValue);
  };

  const showOnlyAvailableInAppPrompt = useCallback(() => {
    setIsOnlyAvailableInAppPromptOpen(true);
  }, [setIsOnlyAvailableInAppPromptOpen]);

  const closeOnlyAvailableInAppPrompt = useCallback(() => {
    setIsOnlyAvailableInAppPromptOpen(false);
  }, [setIsOnlyAvailableInAppPromptOpen]);

  const value: UIContextInterface = {
    isMuted,
    toggleMute,
    isOnlyAvailableInAppPromtOpen,
    showOnlyAvailableInAppPrompt,
    closeOnlyAvailableInAppPrompt,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
